:root {
  --accent: #FD4D4D;
  /* --bg: #0A0E10; */
  --bg: #0A0E12;
  --color: #DEE3E9;
  --sec: #151A20;
  --secCol: #5F718C;
  --ter: #242C37;
  --terHov: #343C47;
}

body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-track {
  background: transparent;
}
body::-webkit-scrollbar-thumb {
  background-color: var(--terHov);
  border-radius: 20px;
  border: none;
}

html,
body {
  background-color: var(--bg);
  color: var(--color);
  padding: 0;
  margin: 0;
  font-family: 'Lexend', sans-serif;
}

h1, h2, h3, h4, h5 {
  color: var(--accent);
  font-family: 'Spartan', sans-serif;
}

a {
  text-decoration: none;
  color: var(--accent);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lexend', sans-serif;
  user-select: none;
}

hr {
  border: 1px solid #5F718C66;
  margin: 8px 0;
}

@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@700;900&family=Lexend:wght@400;700&display=swap');