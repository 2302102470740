.pContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 40px 0.5rem;
}

.profileCard {
    width: 600px;
    min-height: 280px;
    border-radius: 8px;
    background-color: var(--ter);
    overflow: hidden;
    position: relative;
}

.bgImg {
    filter: brightness(50%);
    object-position: center;
}

.profile {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 36%;
    background-color: var(--sec);
}

.profileImg {
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    border: 4px solid var(--sec);
    bottom: calc(50% - 6px);
    left: 20px;
    background-color: #fff;
}

.profileData {
    padding: 24px 28%;
}

.profileName {
    font-weight: 900;
    font-size: 20px;
    font-family: 'Spartan', sans-serif;
}

.profileId {
    color: var(--secCol);
    font-size: 14px;
}

@media (max-width: 600px) {
    .container {
        padding: 20px 12px;
    }
    .profileCard {
        width: 100%;
        min-height: 360px;
    }
    .profile {
        height: fit-content;
    }
    .profileImg {
        width: 92px;
        height: 92px;
        bottom: 70%;
    }
    .profileData {
        padding: 60px 28px 30px;
    }
}