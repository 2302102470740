.room {
    width: 100%;
    display: flex;
    flex-direction:  row;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
}

.roomMain {
    width: calc(100vw - 300px);
    min-height: 100vh;
    border-right: 1px solid var(--sec);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    position: relative;
}

.fullScreenToggle {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    transition-duration: 300ms;
}
.fullScreenToggle:hover {
    transform: scale(0.9);
}

.roomSideBar {
    width: 300px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: space-evenly;
    height: 100%;
}

iframe {
    outline: none;
    border: none;
    height: calc((100vw - 300px)/1.7);
    margin: auto 0;
}

.copy {
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    height: 30%;
    min-height: fit-content;
    background-color: var(--sec);
}

.copy h5 {
    margin-bottom: 12px;
}

.copy div span{
    user-select: text;
    font-size: 14px;
}

.copy button {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    padding-left: 12px;
    transition-duration: 300ms;
}

.copy button:hover {
    transform: scale(0.9);
}


.optIcon {
    width: 14px;
    height: 14px;
}

.copy > span {
    cursor: pointer;
    font-size: 14px;
    color: var(--secCol);
}

.roomHR {
    border: 1px solid var(--sec);
}

.header {
    width: 100%;
    padding: 12px 0;
    height: 64px;
    display: flex;
    align-items: center;
}

.headerImg {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.headerName {
    padding: 12px;
    font-weight: 700;
    padding-top: 16px;
    font-family: 'Spartan', sans-serif;
}

.chatting {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: var(--sec);
    position: relative;
    padding: 12px;
    /* padding-bottom: 60px; */
    max-height: 60vh;
}

.chatTextArea {
    width: calc(100% - 12px);
    display: flex;
    align-items: center;
    height: max-content;
    justify-content: space-between;
    position: absolute;
    bottom: 6px;
    left: 6px;
}

#sendChat {
    width: 88%;
    background-color: var(--ter);
    border: none;
    padding: 12px;
    outline: none;
    color: var(--color);
    border-radius: 8px;
    resize: none;
}

#sendChat::-webkit-scrollbar {
    width: 3px;
}
#sendChat::-webkit-scrollbar-track {
    background: transparent;
}
#sendChat::-webkit-scrollbar-thumb {
    background-color: var(--secCol);
    border-radius: 20px;
    border: none;
}

.messages {
    padding-right: 8px;
    max-height: 100%;
    overflow-y: scroll;
}

.messages::-webkit-scrollbar {
    width: 3px;
}
.messages::-webkit-scrollbar-track {
    background: transparent;
}
.messages::-webkit-scrollbar-thumb {
    background-color: var(--ter);
    border-radius: 20px;
    border: none;
}

#lastMsg {
    width: 100%;
    height: 40px;
}

.joinEvent {
    width: 100%;
    font-size: 12px;
    color: var(--accent);
    text-align: center;
    opacity: 0.5;
}

.sndBtn {
    background-color: var(--ter);
    border: none;
    border-radius: 100%;
    padding: 12px;
    outline: none;
    cursor: pointer;
    padding-right: 2px;
    margin-left: 8px;
    overflow: hidden;
    height: 36px;
    width: 36px;
    position: relative;
}

.sndBtn img {
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}


@media (max-width: 768px) {
    .room {
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
    }
    .roomMain {
        width: 100%;
        border: none;
        min-height: 30%;
        height: 40%;
    }
    iframe {
        height: 400px;
    }
    .roomHR {
        border: none;
        margin: 2px;
    }
    .roomSideBar {
        width: 100%;
        height: 60%;
    }
    .chatting {
        min-height: 24vh;
    }
    .copy {
        height: 40%;
    } 
    .header {
        display: none;
    }
    .sndBtn {
        padding-right: 12px;
    }
}