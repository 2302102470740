.spinner {
    border: 3px solid transparent;
    border-top: 3px solid var(--accent);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 12px;
    right: 12px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
} 