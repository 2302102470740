.message {
    width: fit-content;
    max-width: calc(100% - 12px);
    font-size: 14px;
    background-color: var(--terHov);
    padding: 2px 12px 4px;
    border-radius: 8px;
    border-top-left-radius: 0;
    margin-bottom: 4px;
    word-wrap: break-word;
    user-select: text;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.message > span {
    padding-top: 4px;
    font-weight: 900;
    font-size: 10px;
    color: var(--secCol);
}

.sent {
    margin-left: auto;
    border-radius: 20px;
    border-top-right-radius: 0;
    /* background-color: var(--accent); */
    padding-bottom: 2px;
    background: linear-gradient(180deg, var(--accent),#F5004E,#840091) no-repeat center;
    background-attachment: fixed;
}

.joinMessage {
    width: 100%;
    font-size: 12px;
    color: var(--accent);
    text-align: center;
    opacity: 0.5;
    padding: 4px 0;
}