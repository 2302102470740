@import url(https://fonts.googleapis.com/css2?family=Spartan:wght@700;900&family=Lexend:wght@400;700&display=swap);
:root {
  --accent: #FD4D4D;
  /* --bg: #0A0E10; */
  --bg: #0A0E12;
  --color: #DEE3E9;
  --sec: #151A20;
  --secCol: #5F718C;
  --ter: #242C37;
  --terHov: #343C47;
}

body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-track {
  background: transparent;
}
body::-webkit-scrollbar-thumb {
  background-color: #343C47;
  background-color: var(--terHov);
  border-radius: 20px;
  border: none;
}

html,
body {
  background-color: #0A0E12;
  background-color: var(--bg);
  color: #DEE3E9;
  color: var(--color);
  padding: 0;
  margin: 0;
  font-family: 'Lexend', sans-serif;
}

h1, h2, h3, h4, h5 {
  color: #FD4D4D;
  color: var(--accent);
  font-family: 'Spartan', sans-serif;
}

a {
  text-decoration: none;
  color: #FD4D4D;
  color: var(--accent);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lexend', sans-serif;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

hr {
  border: 1px solid #5F718C66;
  margin: 8px 0;
}
.App {
    width: 100%;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.spinWrapper {
    width: 100%;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: var(--accent);
}

.centerSpinner {
    margin-top: 12px;
    border: 4px solid transparent;
    border-top: 4px solid var(--accent);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
} 
.btnDis {
    position: relative;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 40px;
    margin-top: 8px;
    overflow: hidden;
}

.btn {
    position: absolute;
    top: 0;
    width: 100%;
    cursor: pointer;
    font-family: 'Spartan', sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    text-align: center;
    color: var(--color);
    background-color: var(--accent);
    border: none;
    border-radius: 8px;
    outline: none;
    padding: 6px 12px;
    padding-top: 10px;
    transition: top 500ms;
}
.nav {
    min-width: 100%;
    min-height: 60px;
    padding: 32px 32px 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    position: fixed;
    top: 0;
    left: 0;
}

.nav h1 {
    font-weight: 900;
    letter-spacing: 1px;
    cursor: pointer;
}

.userD {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 8px;
}

.userD:hover {
    background-color: var(--sec);
}

.userD img {
    border-radius: 40px;
    margin-right: 12px;
}

.userD span {
    font-weight: 900;
    text-transform: lowercase;
}

.popUpWrapper {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00000022;
}

.logoutWrapper {
    background-color: #00000066;
}

.popUp {
    position: fixed;
    min-width: 180px;
    right: 40px; 
    border: 1px solid var(--ter);
    background-color: var(--sec);
    border-radius: 8px;
    -webkit-animation: popUpAnim 200ms forwards ease-out;
            animation: popUpAnim 200ms forwards ease-out;
    transition: top 200ms;
    overflow: hidden;
}

@-webkit-keyframes popUpAnim {
    from {opacity: 0; top: 80px;}
    to {opacity: 1; top: 100px;}
}

@keyframes popUpAnim {
    from {opacity: 0; top: 80px;}
    to {opacity: 1; top: 100px;}
}

.popUp ul {
    list-style: none;
}

.popUp ul li {
    width: 100%;
    padding: 16px 18px;
    font-size: 14px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    color: var(--color);
}

.popUp ul li:hover {
    background-color: var(--ter);
}

.logout {
    background-color: var(--ter);
    font-weight: 700;
}

.popUp img {
    width: 16px;
    height: 16px;
    margin-right: 12px;
}

.logoutMenu {
    width: 600px;
    min-height: 100px;
    background-color: var(--sec);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 8px;
    padding: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.logoutMenu span {
    position: absolute;
    top: 10px;
    right: 16px;
    cursor: pointer;
}

.logoutButtons {
    padding-top: 32px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    font-family: 'Lexend', sans-serif;
}

.logoutButton {
    background-color: var(--accent);
    font-family: inherit;
    border-radius: 4px;
    outline: none;
    padding: 8px 24px;
    text-align: center;
    min-width: 100px;
    border: none;
    color: var(--col);
    cursor: pointer;
    font-weight: 700;
}

.cancelButton {
    border: none;
    color: var(--col);
    background-color: transparent;
    font-family: inherit;
    text-align: center;
    outline: none;
    padding: 8px 24px;
    min-width: 100px;
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 600px) {
    .nav {
        padding: 24px 12px;
    }
    .popUpWrapper {
        background-color: #00000066;
    }
    .popUp {
        width: 100%;
        bottom: 0;
        left: 0;
        top: auto;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 12px;
        border-top-right-radius: 12px;
        padding: 24px 12px;
        border: none;
    }
    .userD {
        padding: 0;
    }
    .userD img {
        margin: 0;
    }
    .userD span {
        display: none;
    }
    .popUp ul li {
        padding: 18px 18px;
        font-size: 17px;
    }
    .logout {
        background-color: inherit;
    }
    .logoutMenu {
        width: 100%;
    }

    @-webkit-keyframes popUpAnim {
        from {opacity: 0; bottom: -100px;}
        to {opacity: 1; bottom: 0}
    }

    @keyframes popUpAnim {
        from {opacity: 0; bottom: -100px;}
        to {opacity: 1; bottom: 0}
    }

}
.spinner {
    border: 3px solid transparent;
    border-top: 3px solid var(--accent);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
    position: absolute;
    top: 12px;
    right: 12px;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
} 
.container {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    padding: 0 0.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
  
.login {
    background-color: var(--sec);
    border-radius: 8px;
    padding: 44px 40px;
    width: 400px;
    /* min-height: 424px; */
}

.welcome {
    font-size: 27px;
    color: var(--color);
    font-family: 'Poppins', sans-serif;
}

.desc {
    margin: 20px 0;
    font-size: 14px;
    max-width: 92%;
    line-height: 20px;
}

.desc span {
    color: var(--accent);
}

.desc a:hover {
    text-decoration: underline;
}

.buttons {
    margin-top: 40px;
}

.loginB {
    padding: 12px;
    width: 100%;
    cursor: pointer;
    background-color: var(--ter);
    border: none;
    outline: none;
    color: var(--color);
    font-family: inherit;
    font-weight: 700;
    font-size: 15px;
    border-radius: 8px;
    letter-spacing: 1px;
    margin: 20px 0 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.loginB:hover {
    background-color: var(--terHov);
}

.loginI {
    height: 18px;
    width: 18px;
    margin-right: 12px;
    margin-top: 2px;
}

.footer {
    width: 100%;
    padding: 40px;
    position: fixed;
    bottom: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.footerL {
    color: var(--accent);
    font-size: 24px;
    font-weight: 700;
    font-family: 'Spartan', sans-serif;
}

.footerGH {
    width: 24px;
    height: 24px;
}

@media (max-width: 420px) {
    .container {
        padding: 0;
    }
    .login {
        width: 100%;
        border-radius: 0px;
        min-height: -webkit-fit-content;
        min-height: -moz-fit-content;
        min-height: fit-content;
    }
    .footer {
        -webkit-justify-content: center;
                justify-content: center;
    }
    .footerL {
        position: fixed;
        top: 100px;
    }
}
.tpContainer {
    width: 100%;
    height: 100%;
    padding: 40px 10% 20px;
}

.tpContainer h1, h2, h3 {
    margin-top: 20px;
    margin-bottom: 12px;
}

@media (max-width: 768px) {
    .tpContainer {
        padding: 40px 12px;
    }

    .tpContainer ul {
        padding-left: 20px;
    }
}
.pContainer {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    height: 100%;
    padding: 40px 0.5rem;
}

.profileCard {
    width: 600px;
    min-height: 280px;
    border-radius: 8px;
    background-color: var(--ter);
    overflow: hidden;
    position: relative;
}

.bgImg {
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
    object-position: center;
}

.profile {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 36%;
    background-color: var(--sec);
}

.profileImg {
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    border: 4px solid var(--sec);
    bottom: calc(50% - 6px);
    left: 20px;
    background-color: #fff;
}

.profileData {
    padding: 24px 28%;
}

.profileName {
    font-weight: 900;
    font-size: 20px;
    font-family: 'Spartan', sans-serif;
}

.profileId {
    color: var(--secCol);
    font-size: 14px;
}

@media (max-width: 600px) {
    .container {
        padding: 20px 12px;
    }
    .profileCard {
        width: 100%;
        min-height: 360px;
    }
    .profile {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .profileImg {
        width: 92px;
        height: 92px;
        bottom: 70%;
    }
    .profileData {
        padding: 60px 28px 30px;
    }
}
.message {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: calc(100% - 12px);
    font-size: 14px;
    background-color: var(--terHov);
    padding: 2px 12px 4px;
    border-radius: 8px;
    border-top-left-radius: 0;
    margin-bottom: 4px;
    word-wrap: break-word;
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.message > span {
    padding-top: 4px;
    font-weight: 900;
    font-size: 10px;
    color: var(--secCol);
}

.sent {
    margin-left: auto;
    border-radius: 20px;
    border-top-right-radius: 0;
    /* background-color: var(--accent); */
    padding-bottom: 2px;
    background: linear-gradient(180deg, var(--accent),#F5004E,#840091) no-repeat center;
    background-attachment: fixed;
}

.joinMessage {
    width: 100%;
    font-size: 12px;
    color: var(--accent);
    text-align: center;
    opacity: 0.5;
    padding: 4px 0;
}
.room {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction:  row;
            flex-direction:  row;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
}

.roomMain {
    width: calc(100vw - 300px);
    min-height: 100vh;
    border-right: 1px solid var(--sec);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 12px;
    position: relative;
}

.fullScreenToggle {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    transition-duration: 300ms;
}
.fullScreenToggle:hover {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}

.roomSideBar {
    width: 300px;
    padding: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: space-evenly;
            align-items: space-evenly;
    height: 100%;
}

iframe {
    outline: none;
    border: none;
    height: calc((100vw - 300px)/1.7);
    margin: auto 0;
}

.copy {
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    height: 30%;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    background-color: var(--sec);
}

.copy h5 {
    margin-bottom: 12px;
}

.copy div span{
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
    font-size: 14px;
}

.copy button {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    padding-left: 12px;
    transition-duration: 300ms;
}

.copy button:hover {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}


.optIcon {
    width: 14px;
    height: 14px;
}

.copy > span {
    cursor: pointer;
    font-size: 14px;
    color: var(--secCol);
}

.roomHR {
    border: 1px solid var(--sec);
}

.header {
    width: 100%;
    padding: 12px 0;
    height: 64px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.headerImg {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.headerName {
    padding: 12px;
    font-weight: 700;
    padding-top: 16px;
    font-family: 'Spartan', sans-serif;
}

.chatting {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: var(--sec);
    position: relative;
    padding: 12px;
    /* padding-bottom: 60px; */
    max-height: 60vh;
}

.chatTextArea {
    width: calc(100% - 12px);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: -webkit-max-content;
    height: max-content;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    position: absolute;
    bottom: 6px;
    left: 6px;
}

#sendChat {
    width: 88%;
    background-color: var(--ter);
    border: none;
    padding: 12px;
    outline: none;
    color: var(--color);
    border-radius: 8px;
    resize: none;
}

#sendChat::-webkit-scrollbar {
    width: 3px;
}
#sendChat::-webkit-scrollbar-track {
    background: transparent;
}
#sendChat::-webkit-scrollbar-thumb {
    background-color: var(--secCol);
    border-radius: 20px;
    border: none;
}

.messages {
    padding-right: 8px;
    max-height: 100%;
    overflow-y: scroll;
}

.messages::-webkit-scrollbar {
    width: 3px;
}
.messages::-webkit-scrollbar-track {
    background: transparent;
}
.messages::-webkit-scrollbar-thumb {
    background-color: var(--ter);
    border-radius: 20px;
    border: none;
}

#lastMsg {
    width: 100%;
    height: 40px;
}

.joinEvent {
    width: 100%;
    font-size: 12px;
    color: var(--accent);
    text-align: center;
    opacity: 0.5;
}

.sndBtn {
    background-color: var(--ter);
    border: none;
    border-radius: 100%;
    padding: 12px;
    outline: none;
    cursor: pointer;
    padding-right: 2px;
    margin-left: 8px;
    overflow: hidden;
    height: 36px;
    width: 36px;
    position: relative;
}

.sndBtn img {
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: absolute;
}


@media (max-width: 768px) {
    .room {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        min-height: 100vh;
    }
    .roomMain {
        width: 100%;
        border: none;
        min-height: 30%;
        height: 40%;
    }
    iframe {
        height: 400px;
    }
    .roomHR {
        border: none;
        margin: 2px;
    }
    .roomSideBar {
        width: 100%;
        height: 60%;
    }
    .chatting {
        min-height: 24vh;
    }
    .copy {
        height: 40%;
    } 
    .header {
        display: none;
    }
    .sndBtn {
        padding-right: 12px;
    }
}
