.container {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.login {
    background-color: var(--sec);
    border-radius: 8px;
    padding: 44px 40px;
    width: 400px;
    /* min-height: 424px; */
}

.welcome {
    font-size: 27px;
    color: var(--color);
    font-family: 'Poppins', sans-serif;
}

.desc {
    margin: 20px 0;
    font-size: 14px;
    max-width: 92%;
    line-height: 20px;
}

.desc span {
    color: var(--accent);
}

.desc a:hover {
    text-decoration: underline;
}

.buttons {
    margin-top: 40px;
}

.loginB {
    padding: 12px;
    width: 100%;
    cursor: pointer;
    background-color: var(--ter);
    border: none;
    outline: none;
    color: var(--color);
    font-family: inherit;
    font-weight: 700;
    font-size: 15px;
    border-radius: 8px;
    letter-spacing: 1px;
    margin: 20px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginB:hover {
    background-color: var(--terHov);
}

.loginI {
    height: 18px;
    width: 18px;
    margin-right: 12px;
    margin-top: 2px;
}

.footer {
    width: 100%;
    padding: 40px;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
}

.footerL {
    color: var(--accent);
    font-size: 24px;
    font-weight: 700;
    font-family: 'Spartan', sans-serif;
}

.footerGH {
    width: 24px;
    height: 24px;
}

@media (max-width: 420px) {
    .container {
        padding: 0;
    }
    .login {
        width: 100%;
        border-radius: 0px;
        min-height: fit-content;
    }
    .footer {
        justify-content: center;
    }
    .footerL {
        position: fixed;
        top: 100px;
    }
}